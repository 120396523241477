<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PROJECT_TYPE_TYPES } from './store'
import { ROUTES as PROJECT_TYPE_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import ProjectTypeTable from './ProjectTypeTable'
import ProjectTypeCommonFilters from './ProjectTypeCommonFilters'
import ProjectTypeEdit from './ProjectTypeEdit.vue'

export default {
  name: 'ProjectTypeList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': ProjectTypeTable,
    'common-filters': ProjectTypeCommonFilters,
    'component-edit': ProjectTypeEdit,
    'component-detail': ProjectTypeEdit
  },
  data () {
    return {
      PROJECT_TYPE_ROUTES,
      title: this.$t('Project types'),
      rolePerm: ['project_projecttype_list'],
      actionEnablePermission: ['project_projecttype_enable'],
      actionDisablePermission: ['project_projecttype_disable'],
      searchFields: [
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 4 },
        { key: 'filter__slug__icontains', placeholder: this.$t('Slug'), type: 'text', col: 4 },
        { key: 'filter__description__icontains', placeholder: this.$t('Description'), type: 'text', col: 4 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...PROJECT_TYPE_TYPES.GENERIC.project.projecttype.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.PROJECT_TYPE_ROUTES.PROJECT_TYPE_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('project_projecttype_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: PROJECT_TYPE_TYPES.GENERIC.project.projecttype.LIST.MUTATIONS.setSelectedList,
      setSelectAll: PROJECT_TYPE_TYPES.GENERIC.project.projecttype.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...PROJECT_TYPE_TYPES.GENERIC.project.projecttype.LIST.ACTIONS
    })
  }
}
</script>
